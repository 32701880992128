<template>
  <div id="projectPage">
    <div id="go_back_home_link" class="fixed-top">
      <div class="container">
        <div class="row">
          <div class="col">
            <div id="go-back-button">
              <a href="https://chenxixi.es/" class="align-self-start text-uppercase">&lt; Projects &lt;</a>
            </div>
            <div class="dropdown">
              <button class="dropdown-toggle text-uppercase" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="true">
                Reconstruction
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <router-link class="dropdown-item" to="/projects/laniakea">
                    Laniakea
                  </router-link>
                </li>
                <div class="dropdown-divider"></div>
                <li>
                  <router-link class="dropdown-item" to="/projects/heritageedible">
                    Heritage edible
                  </router-link>
                </li>
                <div class="dropdown-divider"></div>
                <li>
                  <router-link class="dropdown-item" to="/projects/fmcoo">
                    FMCOO
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="project-content">
      <section id="first-image">
        <div class="container">
          <div class="row">
            <div class="col">
              <img class="img-fluid" src="../assets/img/Reconstruccio/reglas.jpg">
            </div>
          </div>
        </div>
      </section>
      <section id="project-panel">
        <div class="container">
          <div class="row">
            <div class="col-xl-4 justify-content-start">
              <div class="project-caracteristics">
                <div class="carateristic-panel">
                  <div class="tooltip-preference">
                    <div class="tooltip-img">
                      <img src="../assets/img/projects_details/folder_blue.png">
                    </div>
                    <span class="tooltiptext">Genre</span>
                  </div>
                  <p>Board Game</p>
                </div>
                <div class="carateristic-panel">
                  <div class="tooltip-preference">
                    <div class="tooltip-img">
                      <img src="../assets/img/projects_details/position_blue.png">
                    </div>
                    <span class="tooltiptext">Position</span>
                  </div>
                  <p>Co-creator, photographer, photo editor</p>
                </div>
                <div class="carateristic-panel">
                  <div class="tooltip-preference">
                    <div class="tooltip-img">
                      <img src="../assets/img/projects_details/year_blue.png">
                    </div>
                    <span class="tooltiptext">Year</span>
                  </div>
                  <p>2017</p>
                </div>
              </div>
            </div>
            <div class="col-xl-4">
              <p>
              This project intends to make people aware of the reality of climate change through a board game.
              </p>
            </div>
            <div class="col-xl-4">
              <p>
              This game has made use of a series of creative techniques and has gone through a large number of processes in order to become materialized.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="project-images">
        <div class="container">
          <div class="row">
            <div class="col-xl-7">
              <img src="../assets/img/Reconstruccio/mock_up.png">
              <img src="../assets/img/Reconstruccio/proces.png">
            </div>
            <div class="col-xl-5">
              <img src="../assets/img/Reconstruccio/cartell.png">
              <img src="../assets/img/Reconstruccio/ruleta3.jpg">
            </div>
          </div>
        </div>
      </section>
    </div>
    <div id="nav-buttons">
      <div class="container">
          <div class="row justify-content-between">
            <div class="col-auto align-self-start">
              <button class="btn-lg button-end">
                  <router-link to="/projects/heritageEdible">
                    <p class="text-uppercase align-self-start">Previous</p>
                  </router-link>
              </button>
            </div>
            <div class="col-auto align-self-end">
              <button class="btn-lg button-end">
                  <router-link to="/projects/FMCOO">
                    <p class="text-uppercase align-self-end">next</p>
                  </router-link>
              </button>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
</script>
<style>
</style>

<template>
<div>
   <div id="experimental-background"></div>
   <audio id="audio" autoplay loop>
      <source src="../assets/audio/music.flac" type="audio/flac">
   </audio>
   <div class="d-none d-lg-block">
      <router-link id="buttontouseful" to="/"></router-link>
    </div>
   <div class="scene">
     <button id="audiobutton" @click="toggleSound(), audiobuttonchange()">
       <img id="volum" src="../assets/img/experimental/volum.svg">
       <img id="volum_none" src="../assets/img/experimental/volum_none.svg">
     </button>
      <div class="background-scene">
         <img src="../assets/img/experimental/1366_15fr/fondo_npcs.jpg" alt="">
      </div>
      <div class="parents-sprite" @click="showPsbubble()">
         <img id="parents" src="../assets/img/experimental/1366_15fr/father_mother_sprite.png">
      </div>
      <div id="parents-bubble-panel" v-if ="psbubble">
         <div class="bubble bubble-arrow">
            <div id="parents-bubble-content">
               <div id="psb0">
                <p>Did you see that slime from there? I saw her grow little by little, she was born when we got here, by the way, it was a LOOOOONG getaway! </p>
                <div class="button-next" @click="psbnextfunction()"></div>
               </div>
               <div id="psb1">
                <p>If you ask me what she likes, I can tell you, although I don't know what they are exactly.</p>
                <div class="button-next" @click="psbnextfunction()"></div>
               </div>
               <div id="psb2">
                 <iframe src="https://giphy.com/embed/NFA61GS9qKZ68" width="100%" height="100%" class="giphy-embed" allowFullScreen></iframe>
                  <p>She likes to read novels online,</p>
                  <div class="button-next" @click="psbnextfunction()"></div>
               </div>
               <div id="psb3">
                <iframe src="https://giphy.com/embed/ii2Brp8O0RS0SA3dVf" width="100%" height="100%" class="giphy-embed" allowFullScreen></iframe>
                <p>do some sports,</p>
                <div class="button-next" @click="psbnextfunction()"></div>
               </div>
               <div id="psb5">
                <iframe src="https://giphy.com/embed/y70jyPYRIL1sZOcRJF" width="100%" height="100%" class="giphy-embed" allowFullScreen></iframe>
                <p> watch anime,</p>
                <div class="button-next" @click="psbnextfunction()"></div>
               </div>
               <div id="psb6">
                <iframe src="https://giphy.com/embed/xUA7bdrXps7IlbZGzS" width="100%" height="100%" class="giphy-embed" allowFullScreen></iframe>
                <p>and hang out with the other slimes.</p>
                <div class="button-next" @click="psbnextfunction()"></div>
               </div>
               <div id="psb7">
                <p>She also told me that in the future he wants to dedicate himself to video editing, programming, design or something new.</p>
                <button class="close-button" @click="showPsbubble()"> Close</button>
                </div>
            </div>
         </div>
      </div>
      <div class="me-sprite" @click="showMesbubble()">
         <img id="me" src="../assets/img/experimental/1366_15fr/me_sprite.png" alt>
      </div>
      <div id="me-bubble-panel" v-if ="mesbubble">
         <div class="bubble bubble-arrow">
            <div id="me-bubble-content">
               <div id="mesb0">
                <p>Every time the essence goes through some experiences, I go through an adventure. The adventures are magnificent, leaving me unforgettable memories. </p>
                <div class="button-next" @click="mesbnextfunction()"></div>
               </div>
               <div id="mesb1">
                 <p>These are my treasures: Laniakea, FMCOO, reconstruccio and heritage edible.</p>
               <div class="button-next" @click="mesbnextfunction()"></div>
               </div>
               <div id="mesb2">
                <img src="../assets/img/Laniakea/web.jpg">
                <p>Laniakea</p>
                <div class="button-next" @click="mesbnextfunction()"></div>
               </div>
               <div id="mesb3">
                <img src="../assets/img/fmcoo/FMCOO_logo.png">
                <p>FMCOO</p>
                <div class="button-next" @click="mesbnextfunction()"></div>
               </div>
               <div id="mesb4">
                <img src="../assets/img/Reconstruccio/mock_up.png">
                <p>Reconstruction</p>
                <div class="button-next" @click="mesbnextfunction()"></div>
               </div>
               <div id="mesb5">
                <img src="../assets/img/heritageEdible/Postcard.png">
                <p>Heritage edible</p>
                <button class="close-button" @click="showMesbubble()"> Close</button>
               </div>
            </div>
         </div>
      </div>
      <div class="mix-sprite" @click="showMsbubble()">
         <img id="mix" src="../assets/img/experimental/1366_15fr/mix_sprite.png" alt="">
      </div>
      <div id="mix-bubble-panel" v-if ="msbubble">
         <div class="bubble bubble-arrow">
            <div id="mix-bubble-content">
               <div id="msb0">
                  <p>😊😊  Ahhhh, hi,</p>
                  <div class="button-next" @click="msbnextfunction()"></div>
               </div>
               <div id="msb1">
                 <p>I was a yellow slime before, but for now, I'm turning into a blue slime, who's going to know what will happen next.</p>
                <div class="button-next" @click="msbnextfunction()"></div>
               </div>
               <div id="msb2">
                 <p>She, different from us, doesn't stop evolving, especially after passing the adventures of <a href="https://www.eram.cat/es" target="_blank">ERAM</a> and <a href="https://cookiebox.es/" target="_blank">Cookie Box S.L.</a></p>
                <button class="close-button" @click="showMsbubble()"> Close</button>
               </div>
            </div>
         </div>
      </div>
      <div class="npc-sprite" @click="showNsbubble()">
         <img id="npc" src="../assets/img/experimental/1366_15fr/npc_sprite.png" alt="">
      </div>
      <div id="npc-bubble-panel" v-if ="nsbubble">
         <div class="bubble bubble-arrow">
            <div id="npc-bubble-content">
              <div id="nsb0">
                <iframe src="https://giphy.com/embed/l46CxmW82zcQiRghG" width="100%" height="100%" class="giphy-embed" allowFullScreen></iframe>
                <div class="button-next" @click="nsbnextfunction()"></div>
              </div>
              <div id="nsb1">
                <p>When we just hang out and hang out together, she's so cute. But when it comes to choosing which adventure to follow, she already gets very serious </p>
                <div class="button-next" @click="nsbnextfunction()"></div>
              </div>
               <div id="nsb2">
                  <p>she takes into account about the slimes that will accompany her, their combat modes and the type of adventure.
                  </p>
                  <button class="close-button" @click="showNsbubble()"> Close</button>
               </div>
            </div>
         </div>
      </div>
      <div class="yellow-sprite"  @click="showYsbubble()">
         <img id="yellow" src="../assets/img/experimental/1366_15fr/yellow_sprite.png" alt="">
      </div>
      <div id="yellow-bubble-panel" v-if ="ysbubble">
         <div class="bubble bubble-arrow">
            <div id="yellow-bubble-content">
              <div id="ysb0">
                <p>I remember when she invited me to venture, she wanted to find where she came from, our origin.</p>
               <div class="button-next" @click="ysbnextfunction()"></div>
              </div>
              <div id="ysb1">
                <p>Although I told her that it was too difficult because it collapsed, she also began the adventure with our companions.</p>
               <div class="button-next" @click="ysbnextfunction()"></div>
              </div>
              <div id="ysb2">
                <p>The adventure was dangerous, but fortunately everyone did what they had to do.</p>
                <div class="button-next" @click="ysbnextfunction()"></div>
               </div>
              <div id="ysb3">
                <iframe src="https://giphy.com/embed/uD9ys1HkUSbuMJciFe" width="100%" height="100%" class="giphy-embed" allowFullScreen></iframe>
                <div class="button-next" @click="ysbnextfunction()"></div>
               </div>
              <div id="ysb4">
                <p>She was responsible, patient ... she could finish the tasks that she played perfectly both individually or in groups.</p>
                <div class="button-next" @click="ysbnextfunction()"></div>
              </div>
              <div id="ysb5">
                <p>Thanks to her, we were able to get out of the most complicated situations.</p>
                <div class="button-next" @click="ysbnextfunction()"></div>
              </div>
              <div id="ysb6">
                <iframe src="https://giphy.com/embed/wIXg7i1X8OW5i" width="100%" height="100%" class="giphy-embed" allowFullScreen></iframe>
                <div class="button-next" @click="ysbnextfunction()"></div>
              </div>
              <div id="ysb7">
                <p>Finally, we have not reached our homeland, but we have found a <a href="mailto:xixichenyy@gmail.com">magical portal</a> that communicates the 2 sides.</p>
                <button class="close-button" @click="showYsbubble()"> Close</button>
              </div>
            </div>
         </div>
      </div>
   </div>
</div>
</template>
<script>
export default {
  name: 'Experimental',
  data () {
    return {
      ani_me: '',
      nsbubble: false,
      msbubble: false,
      psbubble: false,
      ysbubble: false,
      mesbubble: false
    }
  },
  methods: {
    toggleSound () {
      var audioElem = document.getElementById('audio')
      if (audioElem.paused) audioElem.play()
      else {
        audioElem.pause()
      }
    },
    audiobuttonchange () {
      var volum = document.getElementById('volum')
      var volumNone = document.getElementById('volum_none')
      if (volum) {
        if (volum.style.display === 'none') {
          volum.style.display = 'block'
          volumNone.style.display = 'none'
        } else {
          volum.style.display = 'none'
          volumNone.style.display = 'block'
        }
      }
    },
    nsbnextfunction () {
      var nsbElems = document.querySelectorAll('#npc-bubble-content>div')
      for (var i = 0; i < nsbElems.length; i++) {
        if (nsbElems[i].style.display !== 'none') {
          nsbElems[i].style.display = 'none'
          if (i === nsbElems.length - 1) {
            nsbElems[0].style.display = 'block'
          } else {
            nsbElems[i + 1].style.display = 'block'
          }
          break
        }
      }
    },
    msbnextfunction () {
      var msbElems = document.querySelectorAll('#mix-bubble-content>div')
      for (var i = 0; i < msbElems.length; i++) {
        if (msbElems[i].style.display !== 'none') {
          msbElems[i].style.display = 'none'
          if (i === msbElems.length - 1) {
            msbElems[0].style.display = 'block'
          } else {
            msbElems[i + 1].style.display = 'block'
          }
          break
        }
      }
    },
    psbnextfunction () {
      var psbElems = document.querySelectorAll('#parents-bubble-content>div')
      for (var i = 0; i < psbElems.length; i++) {
        if (psbElems[i].style.display !== 'none') {
          psbElems[i].style.display = 'none'
          if (i === psbElems.length - 1) {
            psbElems[0].style.display = 'block'
          } else {
            psbElems[i + 1].style.display = 'block'
          }
          break
        }
      }
    },
    ysbnextfunction () {
      var ysbElems = document.querySelectorAll('#yellow-bubble-content>div')
      for (var i = 0; i < ysbElems.length; i++) {
        if (ysbElems[i].style.display !== 'none') {
          ysbElems[i].style.display = 'none'
          if (i === ysbElems.length - 1) {
            ysbElems[0].style.display = 'block'
          } else {
            ysbElems[i + 1].style.display = 'block'
          }
          break
        }
      }
    },
    mesbnextfunction () {
      var mesbElems = document.querySelectorAll('#me-bubble-content>div')
      for (var i = 0; i < mesbElems.length; i++) {
        if (mesbElems[i].style.display !== 'none') {
          mesbElems[i].style.display = 'none'
          if (i === mesbElems.length - 1) {
            mesbElems[0].style.display = 'block'
          } else {
            mesbElems[i + 1].style.display = 'block'
          }
          break
        }
      }
    },
    showNsbubble () {
      this.nsbubble = !this.nsbubble
    },
    showMsbubble () {
      this.msbubble = !this.msbubble
    },
    showPsbubble () {
      this.psbubble = !this.psbubble
    },
    showYsbubble () {
      this.ysbubble = !this.ysbubble
    },
    showMesbubble () {
      this.mesbubble = !this.mesbubble
    }
  }
}
</script>
<style scoped>
p{
  font-size: 14px !important;
  line-height: 24px !important;
}

.bubble{
  font-size: 14px !important;
  line-height: 24px !important;
}

#audiobutton{
  top: 5%;
  right: 3%;
  position: absolute;
  width:50px;
  height:50px;
  background-color: var(--blue);
  border:none;
  border-radius: 100%;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

#volum{
  width: 25px;
  height: auto;
  margin: auto;
  display: grid;
  align-items: center;
}

#volum_none{
  width: 25px;
  height: auto;
  margin: auto;
  display: grid;
  align-items: center;
  display:none;
}

#experimental-background{
   background-image: url("../assets/img/experimental/motivo-experimental.svg");
   background-repeat: repeat;
   background-size: 100px 100px;
   height: 100vh;
   width: 100vw;
   position: absolute;
}

#buttontouseful{
  height: 100vh;
  width: 50px;
  position: fixed;
  z-index: 1031;
  left: 0;
  border: none;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
  background-image: url("../assets/img/experimental/web-1920.jpg");
  background-position: left center;
  background-color: white;
  background-size: cover;
  border-right: 5px solid var(--darkblue)
}

#buttontouseful:hover{
  width: 110px;
  background-size: cover;
  background-repeat: no-repeat;
}

#buttontouseful:after {
  content: '››';
  position: absolute;
  opacity: 1;
  transition: 0.3s;
  left: -10px;
  color: var(--darkblue);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: right;
  font-size: 50px;
  font-family: Comfortaa;
  width: 100%;
}

#buttontouseful:hover:after {
  opacity: 1;
  left:40px;
}

.scene{
   position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* npc-slime */
.npc-sprite{
   height: 66px;
   width: 129px;
   overflow: hidden;
   position: absolute;
   right:45%;
   top: 44%;
   cursor: pointer;
}

.npc-sprite:hover #npc{
   animation: animation 1.533s steps(23) infinite;
}

#npc{
   position: absolute;
   top: 0;
   left: 0;
   animation: animation 1.533s steps(23) 3;
   animation-delay: 5s;
}

#npc-bubble-panel .bubble{
  bottom: 58%;
  left: 48%;
  width: 350px;
}

#npc-bubble-panel .bubble-arrow:before{
  left: 47px;
  bottom: -10px;
  border-left: 40px solid #fff;
  border-right: 25px solid transparent;
  border-top: 22px solid #fff;
  border-bottom: 39px solid transparent;
  z-index: 0;
}

#nsb1,#nsb2{
   display: none;
}

#npc-bubble-content{
   margin:15px;
}

/* mix-slime */

.mix-sprite{
   height: 71px;
   width: 78px;
   overflow: hidden;
   position: absolute;
   right: 37%;
    top: 44%;
    cursor:pointer;
}

.mix-sprite:hover #mix{
   animation: animation 1.533s steps(23) infinite;
}

#mix{
    animation: animation 1.533s steps(23) 2;
    animation-delay: 2s;
    position: absolute;
    top: 0;
    left: 0;
}

#mix-bubble-panel .bubble{
  bottom: 59%;
  left: 57%;
  width: 350px;
}

#mix-bubble-panel .bubble-arrow:before{
  left: 47px;
  bottom: -10px;
  border-left: 40px solid #fff;
  border-right: 25px solid transparent;
  border-top: 22px solid #fff;
  border-bottom: 29px solid transparent;
  z-index: 0;
}

#msb1, #msb2{
   display: none;
}

#mix-bubble-content{
   margin:15px;
}

/* parents-slime */

#parents{
   animation: animation 2.8s steps(43) infinite;
    position: absolute;
    top: 0;
    left: 0;
}

.parents-sprite{
   height: 196px;
   width: 410px;
   overflow: hidden;
   position: absolute;
   top: 66%;
   right: 10%;
   cursor:pointer;
}

#parents-bubble-panel .bubble{
  bottom: 35%;
  right: 10%;
  width: 350px;
}

#parents-bubble-panel .bubble-arrow:before{
  left: 47px;
  bottom: -10px;
  border-left: 50px solid #fff;
  border-right: 25px solid transparent;
  border-top: 40px solid #fff;
  border-bottom: 29px solid transparent;
  z-index: 0;
}

#psb1, #psb2, #psb3, #psb4, #psb5, #psb6, #psb7{
   display: none;
}

#parents-bubble-content{
   margin:15px;
}

/* yellow-slime */

#yellow{
    animation: animation 1.533s steps(23) infinite;
    position: absolute;
    top: 0;
    left: 0;
}

.yellow-sprite{
   height: 265px;
   width: 135px;
   overflow: hidden;
   position: absolute;
   left: 14%;
   top: 25%;
   cursor:pointer;
}

#yellow-bubble-panel .bubble{
  bottom: 60%;
  left: 20%;
  width: 350px;
}

#yellow-bubble-panel .bubble-arrow:before{
  left: 30px;
  bottom: 2px;
  border-left: 40px solid #fff;
  border-right: 25px solid transparent;
  border-top: 40px solid #fff;
  border-bottom: 29px solid transparent;
  z-index: 0;
}

#ysb1,#ysb2,#ysb3,#ysb4,#ysb5,#ysb6,#ysb7{
   display: none;
}

#yellow-bubble-content{
   margin:15px;
}

/* me-slime */
.me-sprite{
   height: 132px;
   width: 229px;
   overflow: hidden;
   position: absolute;
   left: 23%;
   bottom: 19%;
   cursor:pointer;
}

#me{
    animation: animation 2.8s steps(42) infinite;
}

#me-bubble-panel img{
  width:100%;
  height: 100%;
}
#me-bubble-panel .bubble{
  bottom: 35%;
left: 31%;
width: 350px;
z-index: 1;
}

#me-bubble-panel .bubble-arrow:before{
  left: 47px;
  bottom: -10px;
  border-left: 40px solid #fff;
  border-right: 25px solid transparent;
  border-top: 35px solid #fff;
  border-bottom: 35px solid transparent;
  z-index: 0;
}

#mesb1,#mesb2,#mesb3,#mesb4,#mesb5{
   display: none;
}

#me-bubble-content{
   margin:15px;
}

.bubble{
  position: absolute;
  font-size: 14px;
  line-height: 24px;
  background: #fff;
  border-radius: 50%;
  padding: 40px;
  text-align: center;
  color: var(--darkblue);
  -webkit-box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.35);
  box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.35);
  font-family: Comfortaa;
}

.bubble-arrow:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
}

@keyframes animation{
   from {
      transform: translate3d(0px,0,0)
   }
   to {
      transform: translate3d(-100%,0,0)
   }
}

.button-next{
  background-image: url("../assets/img/experimental/arrow_down.svg");
  background-size: cover;
  background-repeat: no-repeat;
  margin-top:10px;
  margin-right: auto;
  margin-left: auto;
  width:25px;
  height:14px;
  cursor:pointer;
  animation: button-next ease-in-out 1.5s infinite;
}

.close-button{
   background-color: var(--darkblue);
   font-size: 14px;
   font-family: Comfortaa;
   color:var(--blue);
   border:none;
   border-bottom: 1px solid var(--darkblue);
   box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
   border-radius: 40%;
  margin-right: auto;
  margin-left: auto;
}

@keyframes button-next{
   0%{
      transform: translate(0px,0px);
      opacity: 0;
   }
  20%{
      opacity:1;
   }
   100%{
      transform: translate(0px,5px);
      opacity:0;
   }
}
</style>
<style scoped>
#app{
  position: absolute;
}
</style>

<template>
  <div id="projectPage">
    <div id="go_back_home_link" class="fixed-top">
      <div class="container">
        <div class="row">
          <div class="col">
            <div id="go-back-button">
              <a href="https://chenxixi.es/" class="align-self-start text-uppercase">&lt; Projects &lt;</a>
            </div>
            <div class="dropdown">
              <button class="dropdown-toggle text-uppercase" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="true">
                Laniakea
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <router-link class="dropdown-item" to="/projects/heritageedible">
                    Heritage edible
                  </router-link>
                </li>
                <div class="dropdown-divider"></div>
                <li>
                  <router-link class="dropdown-item" to="/projects/reconstruction">
                    Reconstruction
                  </router-link>
                </li>
                <div class="dropdown-divider"></div>
                <li>
                  <router-link class="dropdown-item" to="/projects/fmcoo">
                    FMCOO
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="project-content">
      <section id="first-image">
        <div class="container">
          <div class="row">
            <div class="col">
              <img class="img-fluid" src="../assets/img/Laniakea/web.jpg">
            </div>
          </div>
        </div>
      </section>
      <section id="project-panel">
        <div class="container">
          <div class="row">
            <div class="col-xl-4 justify-content-start">
              <div class="project-caracteristics">
                <div class="carateristic-panel">
                  <div class="tooltip-preference">
                    <div class="tooltip-img">
                      <img src="../assets/img/projects_details/folder_blue.png">
                    </div>
                    <span class="tooltiptext">Genre</span>
                  </div>
                  <p>Project multimedia</p>
                </div>
                <div class="carateristic-panel">
                  <div class="tooltip-preference">
                    <div class="tooltip-img">
                      <img src="../assets/img/projects_details/position_blue.png">
                    </div>
                    <span class="tooltiptext">Position</span>
                  </div>
                  <p>Web programmer, co-creator</p>
                </div>
                <div class="carateristic-panel">
                  <div class="tooltip-preference">
                    <div class="tooltip-img">
                      <img src="../assets/img/projects_details/year_blue.png">
                    </div>
                    <span class="tooltiptext">Year</span>
                  </div>
                  <p>2020</p>
                </div>
                <div class="carateristic-panel">
                  <div class="tooltip-preference">
                    <div class="tooltip-img">
                      <img src="../assets/img/projects_details/links_blue.png">
                    </div>
                    <span class="tooltiptext">Link</span>
                  </div>
                  <a href="https://projectes.eram.cat/laniakea" target="_blank">https://projectes.eram.cat/laniakea</a>
                </div>
              </div>
            </div>
            <div class="col-xl-4">
              <p>
              Laniakea is an immersive experience that combines photographs, illustrations and audiovisual pieces. It aims to show the contrast that forms between the dimension of the human being and the universe.
              </p>
            </div>
            <div class="col-xl-4">
              <p>
              It is a space where the spectator can take a reflective tour of the egocentrism of humanity and its insignificant passage through the world around it. The aim of this experience is to make the user aware of the importance of caring for the planet and nature in general, while showing the beauty of the universe.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="project-images">
        <div class="container">
          <div class="row">
            <div class="col-xl-7">
              <img class="" src="../assets/img/Laniakea/univers.jpg" alt="Responsive image">
              <img class="" src="../assets/img/Laniakea/foto-02.jpg" alt="Responsive image">
            </div>
            <div class="col-xl-5">
              <img class="" src="../assets/img/Laniakea/ilustracio_4.jpg" alt="Responsive image">
              <img class="" src="../assets/img/Laniakea/video.jpg" alt="Responsive image">
            </div>
          </div>
        </div>
      </section>
    </div>
    <div id="nav-buttons">
      <div class="container">
          <div class="row justify-content-end">
            <div class="col-auto align-self-end">
              <button class="btn-lg button-next">
                  <router-link to="/projects/heritageEdible">
                    <p class="text-uppercase">next</p>
                  </router-link>
              </button>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>
<style scoped>
html{
  background-color: var(--blue);
}
</style>
<script>
</script>
